import { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import { blue } from 'theme/colors';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import useWidth from 'hooks/useWidth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { locationsLinks, makesModelsLinks } from './seoLinksData';
import NextLinkMui5 from '../../components/atoms/NextLink';
import LinkMui5 from '../../components/atoms/Link';
import GridMui5 from '@mui/material/Grid';

const Content = styled('div')(() => ({
  overflow: 'hidden',
}));

const ClickGrid = styled('div')(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

const Title = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontSize: '1.25rem',
  },
}));

export type SeoLink = {
  url: string;
  name: string;
};

const LIMIT_MOBILE = 4;
const LIMIT_TABLET = 8;
const LIMIT_DESKTOP = 12;

function getInitLimit(width: string): number {
  switch (width) {
    case 'xs':
      return LIMIT_MOBILE;
    case 'sm':
      return LIMIT_TABLET;
    default:
      return LIMIT_DESKTOP;
  }
}

function SeoLinksGrid({
  links,
  formatName = (name) => name,
  classSuffix = '',
}: {
  links: SeoLink[];
  formatName?: (name: string) => string;
  classSuffix?: string;
}): JSX.Element {
  const width = useWidth();
  const inititalLimit = getInitLimit(width);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleShowMore = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, [setIsOpen]);

  const limit = isOpen ? links.length : inititalLimit;

  if (classSuffix) {
    classSuffix = `-${classSuffix}`;
  }

  return (
    <>
      <GridMui5 container spacing={1}>
        {links.slice(0, limit).map((link) => (
          <GridMui5 item lg={3} md={4} sm={6} xs={12} key={link.name}>
            <LinkMui5
              variant='body2'
              component={NextLinkMui5}
              href={link.url}
              passHref={true}
            >
              {formatName(link.name)}
            </LinkMui5>
          </GridMui5>
        ))}
      </GridMui5>
      <ClickGrid onClick={toggleShowMore}>
        <Button
          className={
            isOpen
              ? `js-seo-links-show-less${classSuffix}`
              : `js-seo-links-show-more${classSuffix}`
          }
          sx={{
            paddingLeft: 0,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          disableRipple
          disableTouchRipple
          endIcon={
            <FontAwesomeIcon
              icon={isOpen ? faChevronUp : faChevronDown}
              color={blue[100]}
            />
          }
        >
          <Typography variant='body2'>
            {`view ${isOpen ? 'less' : 'more'}`}
          </Typography>
        </Button>
      </ClickGrid>
    </>
  );
}

export default function SeoLinks(): JSX.Element {
  return (
    <>
      <Title
        className={'js-seo-links-makes-&-models'}
        variant='h2'
        gutterBottom
      >
        Find Best Deal - Top Makes & Models:
      </Title>
      <Content>
        <SeoLinksGrid
          links={makesModelsLinks}
          classSuffix='makes-&-models'
          formatName={(name) => `Lease ${name}`}
        ></SeoLinksGrid>
      </Content>
      <br></br>
      <Title className={'js-seo-links-locations'} variant='h2' gutterBottom>
        Leasing & Financing Deals Near You:
      </Title>
      <Content>
        <SeoLinksGrid
          links={locationsLinks}
          classSuffix='locations'
          formatName={(name) => `Lease New Car In ${name}`}
        ></SeoLinksGrid>
      </Content>
    </>
  );
}
