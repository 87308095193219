import React, { Fragment, useState } from 'react';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

import RequestVehicleForm from './RequestVehicleForm';
import { BRAND_NAME } from 'shared/utils/constants';

type Props = {
  open: boolean;
  onClose: () => void;
};

function CoomingSoonDialog({ open, onClose }: Props): JSX.Element {
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  return (
    <Dialog onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
      <DialogContent>
        <DialogContentText>
          <Typography variant='body1' gutterBottom>
            {BRAND_NAME} was launched to allow consumers to get great deals on
            vehicle leases in select markets however we are expanding to other
            brands and geographies so let us know what you’re looking for and
            check back soon!
          </Typography>

          {!emailSubmitted && (
            <Fragment>
              <Typography
                variant='body2'
                gutterBottom
                sx={{ fontWeight: 'bold' }}
              >
                Request a car
              </Typography>
              <RequestVehicleForm onSubmit={() => setEmailSubmitted(true)} />
            </Fragment>
          )}
          {emailSubmitted && (
            <Alert severity='success'>Car was requested, thank you!</Alert>
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default CoomingSoonDialog;
