import { useState } from 'react';
import { BannerProps, FormInitialValues } from './types';
import { Form, Field } from 'react-final-form';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import {
  SelectField,
  SelectOption,
  SelectControl,
} from 'components/atoms/Select';
import Grid from '@mui/material/Grid';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import StyledTextField from 'components/atoms/TextField';
import FormControl from '@mui/material/FormControl';
import ComingSoonDialog from 'components/organisms/ComingSoonDialog';
import isNumber from 'lodash/isNumber';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import { useRecoilState } from 'recoil';
import { filterZipcodeAtom } from 'services/zipcode/recoil';
import { useZipcodeValidator } from 'services/zipcode/hooks/useZipcodeValidator';
import {
  AVAILABLE_MONTHLY_PAYMENTS,
  INVALID_ZIP_CODE_ERROR_MSG,
  BODY_STYLES,
} from 'shared/utils/constants';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { useScreenSize } from 'hooks/useScreenSize';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

const FormControlSelect = styled(FormControl)(({ theme }) => ({
  '&.MuiFormControl-root': {
    '& .MuiFormControl-root': {
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
      '& .MuiFormLabel-root': {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(1.5),
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(5),
        },
      },
    },
    '& .MuiInputBase-root > fieldset': {
      border: 0,
      [theme.breakpoints.down('md')]: {
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: 25,
      },
    },
    '& .MuiSelect-root': {
      fontWeight: 'bold',
      fontSize: theme.typography.h6.fontSize,
      color: theme.palette.primary.main,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
      '& .MuiSelect-select': {
        lineHeight: theme.typography.pxToRem(22),
        minHeight: theme.typography.pxToRem(22),
      },
    },
    '& .MuiInputBase-input.Mui-disabled': {
      opacity: 0.5,
    },
  },
}));

const zipcodeInputStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiFormControl-root': {
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-error > input': {
        color: theme.palette.secondary.main,
      },
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(3),
      },
      '&.Mui-focused > fieldset': {
        [theme.breakpoints.up('md')]: {
          border: 0,
        },
      },
      '& > fieldset': {
        border: 0,
        [theme.breakpoints.down('md')]: {
          border: `1px solid ${theme.palette.grey[200]}`,
          borderRadius: '25px',
        },
      },
      '& > input': {
        fontWeight: 'bold',
        paddingLeft: theme.spacing(0),
        paddingTop: theme.spacing(1.5),
        fontSize: theme.typography.h6.fontSize,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
          paddingLeft: theme.spacing(3),
        },
      },
      '& > input::placeholder': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiFormLabel-root': {
      marginTop: theme.spacing(1.5),
      color: theme.palette.grey[600],
      textTransform: 'capitalize',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(3),
      },
    },
  },
});

const selectDropdownStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiPaper-root': {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2.2),
    },
  },
});

const GreySelectLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.grey[600],
  border: 0,
  textTransform: 'capitalize',
  transform: `translate(${theme.spacing(0)}, ${theme.spacing(-3)}) !important`,
  marginTop: theme.spacing(2),
  '&.Mui-focused': {
    color: theme.palette.grey[600],
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
  },
}));

const searchButtonStyles: SxProps<ThemeMui5> = (theme) => ({
  '& .MuiButton-startIcon': {
    margin: 0,
  },
  '& .MuiButton-iconSizeLarge > *:first-child': {
    fontSize: 28,
  },
  '&.MuiButton-root': {
    [theme.breakpoints.down('sm')]: { height: 80 },
  },
});

function SearchForm({ onSubmit }: BannerProps): JSX.Element {
  const { isExtraSmallScreen, isSmallScreen } = useScreenSize();
  const isMobile = isExtraSmallScreen || isSmallScreen;
  const [comingSoonOpened, setComingSoonOpened] = useState<boolean>(false);
  const [filterZipCode, setFilterZipCode] =
    useRecoilState<string>(filterZipcodeAtom);

  const { isZipcodeValid } = useZipcodeValidator();

  const zipCodeValidator = async (zipcode: string) => {
    if (!zipcode?.length) {
      return;
    }

    const { isValid, error } = await isZipcodeValid(zipcode);

    if (!isValid) {
      return error || INVALID_ZIP_CODE_ERROR_MSG;
    }
  };

  const formInitialValues: FormInitialValues = {
    budget: '',
    bodyStyle: '',
  };

  return (
    <Form
      initialValues={formInitialValues}
      onSubmit={({ budget, bodyStyle, zipcode }) => {
        onSubmit({ budget, bodyStyle, zipcode });
      }}
      render={({ handleSubmit }) => (
        <>
          <form className='js-search-deals-form' onSubmit={handleSubmit}>
            <Grid container spacing={isMobile ? 1 : 3} alignItems='center'>
              <Grid item md sm={6} xs={12}>
                <Field name='budget'>
                  {({ input: { name, onChange, value } }) => {
                    return (
                      <FormControlSelect variant='outlined' fullWidth>
                        <SelectControl>
                          <GreySelectLabel>Monthly budget</GreySelectLabel>
                          <SelectField
                            variant='outlined'
                            displayEmpty
                            onChange={onChange}
                            value={value}
                            name={name}
                            dropdownSx={selectDropdownStyles}
                          >
                            <SelectOption value=''>All budgets</SelectOption>
                            {AVAILABLE_MONTHLY_PAYMENTS.map((item, index) => (
                              <SelectOption key={index} value={index}>
                                {item.label}
                              </SelectOption>
                            ))}
                          </SelectField>
                        </SelectControl>
                      </FormControlSelect>
                    );
                  }}
                </Field>
              </Grid>
              <Grid item md sm={6} xs={12}>
                <Field name='bodyStyle'>
                  {({ input: { name, onChange, value } }) => {
                    return (
                      <FormControlSelect variant='outlined' fullWidth>
                        <SelectControl>
                          <GreySelectLabel>Body style</GreySelectLabel>
                          <SelectField
                            variant='outlined'
                            displayEmpty
                            onChange={onChange}
                            value={value}
                            name={name}
                            dropdownSx={selectDropdownStyles}
                          >
                            <SelectOption value=''>
                              All body styles
                            </SelectOption>
                            {BODY_STYLES.map((item, index) => (
                              <SelectOption key={index} value={item}>
                                {item}
                              </SelectOption>
                            ))}
                          </SelectField>
                        </SelectControl>
                      </FormControlSelect>
                    );
                  }}
                </Field>
              </Grid>
              <Grid item md={3} sm={6} xs={9}>
                <Field
                  name='zipcode'
                  validate={zipCodeValidator}
                  defaultValue={filterZipCode}
                >
                  {({ meta, input: { name, onChange, value } }) => (
                    <StyledTextField
                      size='small'
                      name={name}
                      label='Your zip code'
                      placeholder='Enter your zip'
                      variant='outlined'
                      fullWidth
                      value={value}
                      sx={zipcodeInputStyles}
                      inputProps={{ maxLength: 5 }}
                      onChange={(e) => {
                        const zipCode = e.target.value;
                        const zipCodeNum = Number(zipCode);

                        if (
                          zipCode.length &&
                          (!isNumber(zipCodeNum) || isNaN(zipCodeNum))
                        ) {
                          return;
                        }

                        setFilterZipCode(zipCode);
                        onChange(e);
                      }}
                      error={meta.error && meta.touched}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item md={2} sm={6} xs={3}>
                <Box display='flex' justifyContent='flex-end'>
                  <StyledButtonMui5
                    disableElevation
                    sx={searchButtonStyles}
                    variant='contained'
                    color='secondary'
                    fullWidth
                    type='submit'
                    size='medium'
                    startIcon={
                      <StyledFontAwesomeIconMui5 icon={faMagnifyingGlass} />
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
          <ComingSoonDialog
            open={comingSoonOpened}
            onClose={() => setComingSoonOpened(false)}
          />
        </>
      )}
    />
  );
}

export default SearchForm;
