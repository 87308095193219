import { FC, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { getGoogleReviewsAtom } from '../../../recoil/atoms';
import { QuotationMarksIcon } from './QuotationMarksIcon';
import { ReviewGalleryItem } from './ReviewGalleryItem';
import { Subheader } from '../../../containers/about-us/common';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { LeftNavIcon, RightNavIcon } from './CarouselNav';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useScreenSize } from 'hooks/useScreenSize';
import { BRAND_NAME } from 'shared/utils/constants';

const StyledSwiper = styled(Swiper)(({ theme }) => ({
  '& .swiper-pagination-bullet ': {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const GoogleReviews: FC = () => {
  const reviews = useRecoilValue(getGoogleReviewsAtom);
  const { screenSize } = useScreenSize();

  const isPaginationVisible = ['xs', 'sm'].includes(screenSize);
  const isNavigationVisible = !isPaginationVisible;

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <>
      <Subheader color='textSecondary' align='center' component='h2'>
        A word from our happy customers
      </Subheader>

      <Typography variant='h4' align='center' component='p'>
        Client Feedback: See Why Our Customers Love {BRAND_NAME}
      </Typography>

      <Box display='flex' justifyContent='center' pt={5}>
        <QuotationMarksIcon />
      </Box>

      <StyledSwiper
        loop
        pagination={isPaginationVisible ? { clickable: true } : false}
        modules={[Navigation, Pagination]}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
      >
        {reviews.map((review, index) => (
          <SwiperSlide key={index}>
            <div style={{ marginBottom: 50 }}>
              <ReviewGalleryItem key={index} review={review} />
            </div>
          </SwiperSlide>
        ))}
        {isNavigationVisible ? (
          <>
            <RightNavIcon ref={navigationNextRef} />
            <LeftNavIcon ref={navigationPrevRef} />
          </>
        ) : null}
      </StyledSwiper>
    </>
  );
};
