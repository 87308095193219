import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import Box from '@mui/material/Box';
import { NextLinkMui5 } from 'components/atoms/NextLink';
import { grey } from 'theme/colors';
import { styled, useTheme } from '@mui/material/styles';
import Image from 'next/image';
import { DividerLeftMui5, DividerMui5 } from 'components/atoms/Divider';
import { Subheader } from './components/common';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BRAND_NAME } from 'shared/utils/constants';

const ImgWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const AboutUs: React.FC = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Wrapper>
      <Grid container spacing={4}>
        <Grid item md={6} sm={12} xs={12}>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Box color={grey[700]} mt={3} mb={-1}>
              <Subheader variant='h2' color='textSecondary'>
                {BRAND_NAME}: Your Premier Destination for Car Leasing and
                Financing
              </Subheader>
            </Box>
            <Typography variant='h3' gutterBottom>
              Facilitating trust, transparency & fairness
            </Typography>
            <DividerLeftMui5 />
            <Box color={grey[700]} mb={4} maxWidth={450}>
              <Typography variant='body2' gutterBottom>
                At {BRAND_NAME}, we&apos;re dedicated to transforming your car
                buying experience. Whether you&apos;re looking to lease or
                finance, our platform offers an array of options to fit your
                needs. Explore inventory from local dealers at exceptional
                prices, with all rebates and incentives applied. Enjoy a
                straightforward process with the flexibility of choosing between
                leasing or financing. Browse, select, and drive with confidence{' '}
                {'– '}
                {BRAND_NAME} makes it easy to find the perfect car and financing
                plan for you.
              </Typography>
            </Box>
            <Box maxWidth={220}>
              <StyledButtonMui5
                fullWidth
                variant='contained'
                color='primary'
                component={NextLinkMui5}
                href='/about'
                size='small'
              >
                Learn more
              </StyledButtonMui5>
            </Box>
          </Box>
          <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
            <Box
              color={grey[700]}
              mb={-1}
              display='flex'
              justifyContent='center'
            >
              <Subheader color='textSecondary'>About us</Subheader>
            </Box>
            <Typography variant='h4' gutterBottom align='center'>
              Facilitating trust, transparency & fairness
            </Typography>
            <DividerMui5 />
            <Box color={grey[700]} mb={4}>
              <Typography variant='body2' gutterBottom align='center'>
                {BRAND_NAME} was designed to eliminate the discomfort of
                haggling for hours at the dealership by empowering consumers to
                bid on their lease payment instead. Local dealers post inventory
                at unheard of prices, we apply all rebates and incentives and
                you simply bid, win and drive.
              </Typography>
            </Box>
            <Box maxWidth={220} display='flex' margin='0 auto'>
              <StyledButtonMui5
                fullWidth
                variant='contained'
                color='primary'
                component={NextLinkMui5}
                href='/about'
                size='small'
              >
                Learn more
              </StyledButtonMui5>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <ImgWrapper>
            <Image
              alt='Happy people'
              width={sm ? 350 : 550}
              height={sm ? 250 : 450}
              src='/img/home-about-us.webp'
            />
          </ImgWrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
