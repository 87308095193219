import React from 'react';
import FieldText from './FieldText';

import Tooltip from '@mui/material/Tooltip';
import InputAdornment, {
  InputAdornmentProps,
} from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Explore from '@mui/icons-material/Explore';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Rest from 'services/rest';
import { useZipcodeValidator } from 'services/zipcode/hooks/useZipcodeValidator';
import { FieldValidator } from 'final-form';
import { INVALID_ZIP_CODE_ERROR_MSG } from 'shared/utils/constants';

type PositionType = {
  coords: {
    latitude: number;
    longitude: number;
  };
};

const rest = new Rest();

type GetPositionType = (GetPositionCallbackType) => void;
type GetPositionCallbackType = (type: PositionType) => void;

const getPosition: GetPositionType = (callback: GetPositionCallbackType) => {
  navigator.geolocation.getCurrentPosition((position: PositionType) => {
    return callback(position);
  });
};

type FieldZipProps = {
  setZipcode: (string) => void;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  label?: string | JSX.Element;
  inputAdornmentProps?: InputAdornmentProps;
  style?: React.CSSProperties;
  showCurrentLocation?: boolean;
};

const FieldZip: React.FC<FieldZipProps> = ({
  setZipcode,
  required = false,
  disabled = false,
  name = 'zipcode',
  label = 'Zipcode',
  inputAdornmentProps = {},
  style,
  showCurrentLocation = true,
}): JSX.Element => {
  const [isSnackbarOpen, toggleSnackbar] = React.useState<boolean>(false);
  const [isLoadingZip, toggleIsLoadingZip] = React.useState<boolean>(false);

  const { isZipcodeValid } = useZipcodeValidator();

  const validateZip: FieldValidator<never> = async (
    zipcode: string,
    allValues,
    fieldState,
  ) => {
    if (!fieldState.touched && !fieldState.dirty) {
      return;
    }

    if (required && !zipcode) {
      return 'ZIP code is required';
    }

    const { isValid, error } = await isZipcodeValid(zipcode, {
      onValidationEnd: () => toggleIsLoadingZip(false),
    });

    if (!isValid) {
      return error || INVALID_ZIP_CODE_ERROR_MSG;
    }
  };

  return (
    <React.Fragment>
      <FieldText
        type='number'
        InputProps={{
          onInput: (event) => {
            const target = event.target as HTMLInputElement;
            target.value = target.value.substring(0, 5);
          },
          endAdornment: showCurrentLocation && (
            <InputAdornment
              onClick={() => {
                if (isLoadingZip) {
                  return;
                }

                getPosition(async (position) => {
                  try {
                    toggleIsLoadingZip(true);

                    const { zipcode } = await rest.getZipcodeByLocation(
                      position.coords,
                    );

                    if (!zipcode) {
                      toggleSnackbar(true);
                    } else {
                      setZipcode(zipcode);
                    }
                  } catch (e) {
                    toggleSnackbar(true);
                  } finally {
                    toggleIsLoadingZip(false);
                  }
                });
              }}
              position='end'
              disablePointerEvents={disabled}
              {...inputAdornmentProps}
            >
              <Tooltip title='Find zip by location'>
                <IconButton size='small'>
                  {isLoadingZip ? <CircularProgress size={20} /> : <Explore />}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        metaCondition={(meta) => meta.touched || meta.dirty}
        required={required}
        disabled={disabled}
        name={name}
        label={label}
        validate={validateZip}
        style={style}
      />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => toggleSnackbar(false)}
      >
        <Alert onClose={() => toggleSnackbar(false)} severity='error'>
          Zip was not found
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default FieldZip;
