import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { isEmail } from 'utils/validators';
import FieldText from 'containers/profile/FieldText';
import FieldZip from 'containers/profile/FieldZip';
import { styled } from '@mui/material/styles';
import { SelectOption } from 'components/atoms/Select';
import FieldSelect from 'containers/profile/FieldSelect';
import Rest from 'services/rest';
import { useRecoilValue } from 'recoil';
import { userAtom } from 'recoil/atoms';
import { Make } from 'shared/types/models';
import { isUsZipcode } from '../../shared/utils/validators';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';

const rest = new Rest();

const FormWrapper = styled('form')(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

type FormData = {
  email: string;
  make: string;
  zipcode: string;
};

type Props = {
  onSubmit: () => void;
};

function RequestVehicleForm({ onSubmit }: Props): JSX.Element {
  const user = useRecoilValue(userAtom);
  const [makes, setMakes] = useState<Make[]>([]);

  useEffect(() => {
    const fetchAllMakes = async () => {
      const makes = await rest.getMakes();
      setMakes(makes);
    };

    fetchAllMakes();
  }, []);

  async function submit(data: FormData) {
    await rest.requestVehicle(data);
    onSubmit();
  }

  function validate(values: FormData) {
    const errors: Partial<FormData> = {};

    if (!isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.zipcode) {
      errors.zipcode = 'Zip code required';
    } else if (!isUsZipcode(values.zipcode)) {
      errors.zipcode = 'Invalid zip code';
    }

    if (!values.make) {
      errors.make = 'Required';
    }

    return errors;
  }

  const renderSelectItems = (makes: Make[]) =>
    makes.map((make) => (
      <SelectOption key={make.id} value={make.id}>
        {make.name}
      </SelectOption>
    ));

  const initialValues: FormData = {
    email: user ? user.email : '',
    zipcode: user ? user.zipcode : '',
    make: '',
  };

  return (
    <Form
      onSubmit={submit}
      initialValues={initialValues}
      validate={validate}
      mutators={{
        setZipcode: ([zipcode], state, utils) => {
          utils.changeValue(state, 'zipcode', () => zipcode);
        },
      }}
      render={({ handleSubmit, submitting, form }) => {
        return (
          <FormWrapper autoComplete='off' onSubmit={handleSubmit} noValidate>
            {!user && (
              <>
                <FieldText
                  label='Email'
                  name='email'
                  helperText='Leave us your email and we will keep you updated!'
                />
                <FieldZip setZipcode={form.mutators.setZipcode} />
              </>
            )}
            <FieldSelect name='make' label='Select Make'>
              {renderSelectItems(makes)}
            </FieldSelect>
            <StyledButtonMui5
              variant='contained'
              color='primary'
              type='submit'
              disabled={submitting}
              onClick={handleSubmit}
            >
              Request
            </StyledButtonMui5>
          </FormWrapper>
        );
      }}
    />
  );
}

export default RequestVehicleForm;
