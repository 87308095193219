import React, { Fragment, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { grey, blue } from 'theme/colors';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { StyledFontAwesomeIconMui5 } from 'components/atoms/FontAwesomeIcon';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { DividerMui5, SmallDividerMui5 } from 'components/atoms/Divider';
import { Subheader } from './components/common';
import Image from 'next/image';
import { useScreenSize } from 'hooks/useScreenSize';
import { BRAND_NAME } from 'shared/utils/constants';

const Icon = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  transition: 'filter 0.3s',
}));

const HowItWorksItem = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  border: `1px solid ${blue[50]}`,
  borderRadius: 20,
  minHeight: 300,
  height: '100%',
  background: theme.palette.common.white,
}));

const Wrapper = styled(Box)(() => ({
  transition: '0.2s',
  background: 'rgba(255,255,255, 0.7)',
  position: 'relative',
}));

const Text = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    transition: '0.2s',
    maxHeight: 80,
    overflow: 'hidden',
  },
}));

const BenefitTitle = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontSize: '1.25rem',
  },
}));

const Content = styled('div')(() => ({
  overflow: 'hidden',
}));

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'isBlured',
})<{ isBlured: boolean }>(({ isBlured }) => ({
  filter: isBlured ? 'blur(4px)' : '',
}));

const benefits = [
  {
    title: 'Lowest Prices & Flexible Financing',

    shortDescription:
      'Your gateway to affordable car leasing and flexible financing. Empowering dealers, ensuring low prices, and offering a seamless experience...',
    description:
      'Your gateway to affordable car leasing and flexible financing. Empowering dealers, ensuring low prices, and offering a seamless experience. Whether you choose to lease or finance, drive away with unbeatable deals today.',
    icon: '/img/icons/icon-prices.svg',
    alt: 'Prices',
    width: 84,
    height: 110,
  },
  {
    title: 'No hidden fees',
    shortDescription:
      'No hidden fees, just transparent leasing and financing. Best lenders and incentives applied automatically for both options...',
    description: `No hidden fees, just transparent leasing and financing. Best lenders and incentives applied automatically for both options. Enjoy complete transparency with full upfront fee disclosure. Drive with clarity and savings, whether you lease or finance.`,
    icon: '/img/icons/icon-fees.svg',
    alt: 'Fees',
    width: 144,
    height: 110,
  },
  {
    title: 'Verified inventory',
    shortDescription: `Explore ${BRAND_NAME}'s quality inventory in real time. Enjoy transparent leasing and financing with available and reliable vehicles...`,
    description: `Explore ${BRAND_NAME}'s quality inventory in real time. Enjoy transparent leasing and financing with available and reliable vehicles. No bait-and-switch, just a seamless car leasing and financing experience.`,
    icon: '/img/icons/icon-inventory.svg',
    alt: 'Car',
    width: 84,
    height: 110,
  },
  {
    title: 'Fast and easy',
    shortDescription: `Streamlined, swift, and stress-free leasing and financing. Enjoy doorstep delivery within 25 miles, with most paperwork handled online...`,
    description: `Streamlined, swift, and stress-free leasing and financing. Enjoy doorstep delivery within 25 miles, with most paperwork handled online. Whether you lease or finance, your convenience is our priority.`,
    icon: '/img/icons/icon-fast.svg',
    alt: 'Fast and easy',
    width: 144,
    height: 110,
  },
];

export const Benefits: React.FC = () => {
  const [shortDescription, setShortDescription] = useState(
    new Set(benefits.map((_, i) => i)),
  );

  const { isSmallScreen, isExtraSmallScreen } = useScreenSize();

  const isCompact = isSmallScreen || isExtraSmallScreen;

  return (
    <Fragment>
      <Box
        color={grey[700]}
        mb={4}
        justifyContent='center'
        alignItems='center'
        display='flex'
        flexDirection='column'
      >
        <Subheader variant='h2' color='textSecondary' align='center'>
          Why {BRAND_NAME} is the Best Choice for Leasing and Financing Your
          Vehicle
        </Subheader>
        <Typography
          variant={isCompact ? 'h4' : 'h3'}
          gutterBottom
          align='center'
        >
          The {BRAND_NAME} benefits
        </Typography>
        <DividerMui5 />
        <Box maxWidth={500}>
          <Typography
            variant='body2'
            gutterBottom
            color='textSecondary'
            align='center'
          >
            {BRAND_NAME} is a premier online platform dedicated to streamlining
            both car leasing and financing experiences. We offer budget-friendly
            car lease options and flexible financing solutions to the everyday
            consumer. Explore our extensive range of vehicles and enjoy
            hassle-free leasing or financing with competitive rates.
          </Typography>
        </Box>
      </Box>
      <Content>
        <Grid container spacing={4}>
          {benefits.map((benefit, i) => (
            <Grid item lg={3} md={3} sm={6} xs={12} key={benefit.title}>
              <HowItWorksItem>
                <Icon>
                  <StyledImage
                    width={benefit.width}
                    height={benefit.height}
                    alt={benefit.alt}
                    src={benefit.icon}
                    isBlured={!shortDescription.has(i)}
                  />
                </Icon>
                <Wrapper mt={!shortDescription.has(i) ? '-130px' : -2} pt={-2}>
                  <BenefitTitle variant='h4'>{benefit.title}</BenefitTitle>
                  <SmallDividerMui5 />
                  <Box color={grey[700]} mt={1}>
                    <Text
                      variant='body2'
                      color='textSecondary'
                      gutterBottom
                      style={{ maxHeight: !shortDescription.has(i) ? 180 : 80 }}
                      // sx={{ maxHeight: !shortDescription.has(i) ? 180 : 80 }}
                    >
                      {shortDescription.has(i)
                        ? benefit.shortDescription
                        : benefit.description}
                    </Text>
                    <Button
                      style={{ textTransform: 'none' }}
                      size='small'
                      onClick={() => {
                        if (shortDescription.has(i)) {
                          shortDescription.delete(i);
                        } else {
                          shortDescription.add(i);
                        }
                        setShortDescription(new Set(shortDescription));
                      }}
                      endIcon={
                        <StyledFontAwesomeIconMui5
                          style={{ fontSize: 14 }}
                          icon={
                            shortDescription.has(i)
                              ? faChevronDown
                              : faChevronUp
                          }
                          sx={{ color: 'inherit' }}
                          color='inherit'
                        />
                      }
                    >
                      Show {shortDescription.has(i) ? 'more' : 'less'}
                    </Button>
                  </Box>
                </Wrapper>
              </HowItWorksItem>
            </Grid>
          ))}
        </Grid>
      </Content>
    </Fragment>
  );
};
